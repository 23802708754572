<template>
  <div class="flex justify-center w-full">
    <div class="w-1/2">
      <div
        v-if="success != 'false'"
        class="flex flex-col items-center p-5"
      >
        <div class="my-5">
          <h2 class="text-primary-dark">
            E-mail verification successful!
          </h2>
        </div>
        <div class="flex mx-auto mt-auto justify-evenly">
          <router-link
            class="my-4 btn w-36 btn-primary h-14"
            to="/login"
          >
            Login here
          </router-link>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-col items-center my-5 h-14">
          <h1 class="text-primary-dark">
            E-mail verification unsuccessful!
          </h1>
          <p>Please try again later</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      success: this.$route.query.success || true
    }
  }
}
</script>

<style>
</style>
